import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/getting-most-from-your-cicd-experience",
  "date": "22nd June 2023",
  "title": "Getting the most from your CI/CD experience",
  "summary": "In this video, I take you through the stages of pipeline maturity and suggest ways to optimise your pipelines for maximum benefit.",
  "author": "Karl Hepworth",
  "tag": "Presentation",
  "tagColor": "green",
  "tags": [{
    "name": "presentation"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Let’s dive into CI/CD maturity!`}</p>
    <p>{`In the video below, I take you through some often-overlooked Continuous Integration and Delivery features that can help all of us cover our bases and squeeze out the value we derive from these systems. `}</p>
    <p>{`CI/CD is an investment! To that end, I remind you why we still need CI/CD tooling and help you pitch its importance to your stakeholders. When making your case it’s important to note that complicated pipelines require effort. `}</p>
    <p>{`At the presentation's end, I’ll take you through the steps required for CI/CD, including solutions that make the process easier.`}</p>
    <p>{`If you’re after more helpful tips for saving time when developing CI/CD pipelines, please get in touch with us so we can chat!`}</p>
    <p>{`The following video was recorded at `}<a parentName="p" {...{
        "href": "https://drupalsouth.org/events/drupalsouth-wellington-2023/schedule"
      }}>{`DrupalSouth 2023`}</a>{` in Wellington.`}</p>
    <h2>{`Watch the video`}</h2>

    <YouTube videoId="2GROJxRc4-w" opts={{
      height: '420',
      width: '100%'
    }} mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      